<!--
 * @Date: 2024-08-15 09:40:36
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-10-12 09:27:28
 * @FilePath: \zyt-mobile-frontend\src\views\me\module\menu\group.vue
-->
<!--
 * 作者：zhang
 * 时间：2024/8/15 上午9:40
 * 功能：我的--点播-代表人
 *
-->


<template>
  <div class="p-menu">
    <menu-btn
      title="集体点播"
      icon="https://img.dev.zhijiangfilm.com/i/2024/10/08/67049fd9661db.png"
      @click="goPath('/ruralFilm/groupOnDemand')"
    ></menu-btn>
    <menu-btn
      title="点播记录"
      icon="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709cd11d2205.png"
      @click="goPath('/ruralFilm/groupOnDemandRecord')"
    ></menu-btn>
    <menu-btn
      title="放映评价"
      icon="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709cd12aac3a.png"
      @click="goPath('/sessionEvaluate')"
    ></menu-btn>
    <menu-btn
      title="放映报告审核"
      icon="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709d077655f8.png"
      @click="goPath('/group/representApproval')"
    ></menu-btn>
    <!-- <menu-btn
      title="我的签名"
      icon="http://cdn.zhijiangfilm.com/2024/08/15/66bdaf79f0d42.png"

    ></menu-btn> -->
  </div>
</template>
<script>
import menuBtn from '../menuBtn.vue'
import { Toast } from 'vant'
import common from '@/utils/common'
import router from '@/router'

export default {
  components: { menuBtn },
  name: 'Person',
  props: {
    userRoleCode: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      // 微信
    }
  },
  methods: {
    goPath(url) {
      console.log(url)
      console.log(common.hasPermission(url, this.userRoleCode))
      if (common.hasPermission(url, this.userRoleCode)) {
        router.push(url+'?type=group')
      } else {
        Toast({
          message:
            '系统监测您不是该功能的授权用户，如有问题请联系您所在的公益放映放映公司处理',
          duration: 1500,
        })
      }
      // this.$router.push(url);
    },
  }
}
</script>
<style scoped lang="less"></style>

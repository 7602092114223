<!--
 * @Date: 2023-11-20 16:58:16
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-09-29 10:27:39
 * @FilePath: \zyt-mobile-frontend\src\components\tabbar\index.vue
-->
<template>
  <div class="tabbar">
    <!--    {{ routerLink }}-->
    <van-tabbar active-color="#244E8A" inactive-color="#B9BCBF" route>
      <van-tabbar-item
        v-for="(item, index) in iconList"
        :key="index"
        :to="item.path"
        replace
      >
        <span>{{ item.name }}</span>
        <template #icon>
          <img :src="routerLink == item.path ? item.active : item.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant'
import Vue from 'vue'
Vue.use(Tabbar).use(TabbarItem)
export default {
  name: 'tabbar',
  mounted() {
    this.routerLink = this.$route.path
  },
  data() {
    return {
      routerLink: '',
      iconList: [
        {
          name: '公益放映',
          path: '/ruralFilm',
          active: require('@/assets/tabbar/homeSelect.png'),
          inactive: require('@/assets/tabbar/home.png'),
        },
        // 日历
        {
          name: '观影日历',
          path: '/calendar',
          active: require('@/assets/tabbar/calendarSelect.png'),
          inactive: require('@/assets/tabbar/calendar.png'),
        },
        // {
        //   name: "放映地图",
        //   path: "/map",
        //   active: require("@/assets/tabbar/mapSelect.png"),
        //   inactive: require("@/assets/tabbar/map.png"),
        // },
        // {
        //   name: "消息",
        //   path: "/msg",
        //   active: require("@/assets/tabbar/msgSelect.png"),
        //   inactive: require("@/assets/tabbar/msg.png"),
        // },
        {
          name: '我的',
          path: '/me',
          active: require('@/assets/tabbar/meSelect.png'),
          inactive: require('@/assets/tabbar/me.png'),
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
.tabbar {
  img {
    width: 29px;
    height: 28px;
  }
}
</style>

<template>
  <div class="me">
    <!-- <van-nav-bar title="我的" /> -->
    <div class="me-head">
      <div class="avatar">
        <van-icon name="contact" color="#fff" size="41" />
      </div>
      <div class="name">
        <p>{{ user.realname }}</p>
        <p>{{ user.username || user.phone }}</p>
      </div>
    </div>
    <!--    用户导航栏-->
    <div class="me-nav">
      <div @click="handleMenuClick(1)">
        <van-icon
          name="https://img.dev.zhijiangfilm.com/i/2024/10/08/67049fda149c7.png"
          size="43"
        />
        <span>个人用户</span>
      </div>
      <div @click="handleMenuClick(2)">
        <van-icon
          name="https://img.dev.zhijiangfilm.com/i/2024/10/08/67049fdc39810.png"
          size="43"
        />
        <span>放映员</span>
      </div>
      <div @click="handleMenuClick(3)">
        <van-icon
          name="https://img.dev.zhijiangfilm.com/i/2024/10/08/67049fd5c1ce0.png"
          size="43"
        />
        <span>集体代表</span>
      </div>
      <div class="active" ref="active"></div>
    </div>
    <!--    用户角色菜单-->
    <div class="me-menu">
      <transition custom-class="block">
        <div>
          <m-person
            v-if="active === 1"
            :userRoleCode="userRoleCode"
            :user="user"
            ref="menu1"
          ></m-person>
          <m-projection
            v-if="active === 2"
            :userRoleCode="userRoleCode"
            :active="active"
            ref="menu2"
          ></m-projection>
          <m-representative
            v-if="active === 3"
            :userRoleCode="userRoleCode"
            :active="active"
            ref="menu3"
          />
        </div>
      </transition>
    </div>
    <pre v-is-dev>
      {{ user }}
    </pre>
    <Tabbar />
  </div>
</template>

<script>
import Tabbar from '@/components/tabbar'
import router from '@/router'
import { Toast } from 'vant'
import common from '@/utils/common'
import person from '@views/me/module/menu/person.vue'
import projection from './module/menu/screen.vue'
import representative from './module/menu/group.vue'
import {api} from '@/api'
import rules from '@/router/routerRules'

export default {
  name: 'me',
  components: {
    Tabbar,
    MPerson: person,
    MProjection: projection,
    MRepresentative: representative,
  },
  data() {
    return {
      userType: '1',
      userRoleCode:'',
      // 角色
      active: 1,
      user: {},
    }
  },
  mounted() {
    this.getUserInfo()
    // this.user = this.$store.state.userInfo
    this.$refs.active.style.transform = `scale(1.1) translateY(5%)`
    this.$refs.active.style.left = `${(this.active - 1) * 30}vw`
  },
  methods: {
    handleMenuClick(index) {
      this.active = index
    },

    getUserInfo() {
      api.getUserInfo({}).then(res => {
        console.log(res)
        const { success, result } = res
        if (success) {
          this.user = result
          this.$store.commit('changeUserInfo', result)
          this.userRoleCode = this.$store.state.userInfo.roles[0].roleCode
          switch (this.userRoleCode) {
            case rules.group:
              this.handleMenuClick(3)
              break
            case rules.screen:
              this.handleMenuClick(2)
              break
            default:
              this.handleMenuClick(1)
              break
          }
        }
      })
    },

    goPath(url) {
      console.log(url)
      // console.log(this.getRouteByUrl(url).meta.rules);
      // console.log(this.getRouteByUrl(url).meta.rules.includes(this.userType));
      console.log(common.hasPermission(url, this.userType))
      if (common.hasPermission(url, this.userType)) {
        router.push(url)
      } else {
        Toast({
          message:
            '系统监测您不是放映员，如有问题请联系您所在的公益放映放映公司处理',
          duration: 1500,
        })
      }
      // this.$router.push(url);
    },
  },
  watch: {
    active(newVal) {
      this.$refs.active.style.transform = `scale(1.1) translateY(5%)`
      this.$refs.active.style.left = `${(newVal - 1) * 30}vw`
    },
  },
}
</script>

<style lang="less" scoped>
.me {
  width: 100%;
  position: relative;
  //padding: 20px 16px;
  box-sizing: border-box;
  min-height: 100vh;

  &-head {
    width: 90%;
    margin: auto;
    padding: 0 16px;
    height: 100px;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    position: relative;
    border-bottom: 1px solid #e5e5e5;

    .avatar {
      width: 66px;
      height: 66px;
      border-radius: 50%;
      background: #8ec8f9;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .name {
      position: absolute;
      top: 50%;
      left: 110px;
      transform: translateY(-50%);

      > p:first-child {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 5px;
      }

      > p:last-child {
        font-size: 16px;
        color: #999999;
      }
    }
  }

  &-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90vw;
    height: 108px;
    background: #e7f4ff;
    border-radius: 6px;
    margin: 16px auto;
    position: relative;

    & > div {
      display: flex;
      //flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 99;
      width: 30vw;

      &:nth-child(1) {
        position: absolute;
        left: 0;
      }

      &:nth-child(2) {
        position: absolute;
        left: 30vw;
      }

      &:nth-child(3) {
        position: absolute;
        left: 60vw;
      }
    }

    & > .active {
      position: absolute;
      background: url('https://img.dev.zhijiangfilm.com/i/2024/10/12/6709cd1661dae.png')
        no-repeat;
      background-size: 100% 100%;
      width: 30vw;
      height: 115px;
      transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);
      transform: scale(1.1) translateY(5%);
      z-index: 1;
      left: 0;
      // 玻璃效果
      &:hover {
        background-size: 117px 115px;
      }
    }
  }

  &-top {
    background: #fff;
    padding: 16px 20px;
  }

  .box {
    height: 72px;
    line-height: 72px;

    span {
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      text-shadow: 0px 1px 5px rgba(82, 116, 197, 0.5);
      margin-left: 20px;
    }
  }

  .record {
    background: url('../../assets/me/bg1.png') no-repeat;
    background-size: 100% 100%;
    margin-bottom: 10px;
  }

  .opinion {
    background: url('../../assets/me/bg2.png') no-repeat;
    background-size: 100% 100%;
  }

  &-menu {
    padding: 0 16px;
  }
}

.box-title {
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC, serif;
  color: #333333;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > a {
    width: 40px;
    height: 20px;
    background: rgba(216, 216, 216, 0.27);
    border-radius: 10px;
    text-align: center;
    font-size: 12px;
    font-family: MicrosoftYaHei, serif;
    color: #b3b5b9;
    line-height: 20px;
  }

  > span {
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #333333;
    line-height: 20px;
    font-weight: bold;

    &:after {
      display: inline-block;
      content: '';
      transform: translate(-45px, 10px);
      border-radius: 5px;
      width: 30px;
      height: 3px;
      background: @color-primary;
    }
  }
}

.p-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.v-enter,
.v-leave-to {
  opacity: 0;
  transform: translateX(100vw);
  position: fixed;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}
</style>

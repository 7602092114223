<!--
 * @Date: 2024-08-15 11:38:02
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-10-12 09:16:23
 * @FilePath: \zyt-mobile-frontend\src\views\me\module\menuBtn.vue
-->
<template>
  <div>
    <div @click="handleClick" class="menu-btn" disabled="disabled">
      <div>
        <img :src="icon" alt="" srcset="" />
      </div>
      <div class="title">{{ title }}</div>
    </div>
  </div>
</template>
<script>
import { Dialog } from 'vant'
export default {
  name: 'MenuBtn',
  props: {
    title: {
      type: String,
      default: '#title',
    },
    path: {
      type: String,
      default: '#path',
    },
    icon: {
      type: String,
      default: 'https://img.dev.zhijiangfilm.com/i/2024/10/08/67049fdc39810.png',
    },
    // 禁用状态
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      if (this.disabled) {
        Dialog.alert({
          message: '暂时不可用',
        })
        return
      }
      this.$emit('click', this.path)
    },
  },
}
</script>
<style scoped lang="less">
.menu-btn {
  width: 44vw;
  display: flex;
  flex-direction: column;
  border: 1px solid red;
  text-align: center;
  align-content: center;
  height: 108px;
  background: #ffffff;
  border-radius: @border-radius-base;
  margin: 2vw 0;
  border: 1px solid #e6e8eb;
  align-content: center;
  justify-content: space-evenly;
  > div:first-child {
    align-items: center;
    > img {
      width: 40px;
    }
  }

  > .title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    //垂直居中
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
